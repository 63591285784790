import React, { useState } from "react";
import { Grid, Typography, Box, Link, Button } from "@mui/material";
import resim from "../assets/mmob.png";
import bg from "../assets/Hero.webp";
import bgmobile from "../assets/hero-mobile.webp";
import handshake from "../assets/handshake.webp";
import swords from "../assets/swords.webp";
import cup from "../assets/cup.webp";
import google from "../assets/google.png";
import apple from "../assets/apple.png";

const Homepage = () => {
  const redirectTo = (url) => {
    // Burada URL'e yönlendirme işlemlerini gerçekleştirebilirsiniz
    window.location.href = url;
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const imageWidth = windowWidth <= 600 ? 200 : 400;
  const imageWidth2 = windowWidth <= 600 ? 150 : 300;
  const bgfo = windowWidth <= 600 ? "auto" : "100vh";

  return (
    <Grid
      container
      sx={{
        backgroundImage: "linear-gradient(45deg, #BA55D3 30%, #4B0082 90%)",
        p: 5,
        height: bgfo,
      }}
      className="ourProductMobile"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        item
        xs={12}
        sm={4}
        justifyContent="center"
        alignItems="center"
        display="flex"
      >
        <img
          loading="lazy"
          src={resim}
          alt="Gamer Arena Phone"
          style={{ width: imageWidth }}
        />
      </Grid>
      <Grid item xs={12} sm={8} container spacing={5} alignItems="center">
        <Grid item xs={12}>
          <Typography
            variant="h2"
            sx={{
              backgroundImage:
                "linear-gradient(45deg, #FE6B8B 30%, #AB1241 90%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              fontWeight: "bold", // Kalın yazı
              letterSpacing: "-1px", // Harfleri birbirine yakınlaştırma
            }}
          >
            Play&Earn Mobile Gaming
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <img loading="lazy" src={handshake} alt="Find An Opponent" />
          <Typography variant="h4" sx={{ color: "white" }}>
            Play
          </Typography>
          <Typography sx={{ color: "white" }}>
            Experience competitive games developed by Gamer Arena in one
            application.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <img loading="lazy" src={swords} alt="Show Yourself" />
          <Typography variant="h4" sx={{ color: "white" }}>
            Compete
          </Typography>
          <Typography sx={{ color: "white" }}>
            Join duels and tournaments and feel the excitement of competition.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <img loading="lazy" src={cup} alt="Win Rewards" />
          <Typography variant="h4" sx={{ color: "white" }}>
            Earn
          </Typography>
          <Typography sx={{ color: "white" }}>
            Win GA Coins and prove your gaming skills.
          </Typography>
        </Grid>

        <Grid item xs={12} display="flex" sx={{ mt: 4 }}>
          <div
            onClick={() =>
              redirectTo(
                "https://play.google.com/store/apps/details?id=com.gamerarenaapp"
              )
            }
          >
            <img
              loading="lazy"
              src={google}
              alt="Find An Opponent"
              style={{ width: imageWidth2 }}
            />
          </div>
          <div
            className="tip"
            onClick={() =>
              redirectTo(
                "https://apps.apple.com/tr/app/gamer-arena-play-compete-earn/id1641530825?l=tr"
              )
            }
            style={{ paddingLeft: 5 }}
          >
            <img
              loading="lazy"
              src={apple}
              alt="Show Yourself"
              style={{ width: imageWidth2, margin: 0 }}
            />
          </div>
        </Grid>
        <Grid item display="flex" sx={{ mt: 2 }}>
          <div
            onClick={() =>
              redirectTo("https://www.gamerarenaapp.com/terms-of-service")
            }
            style={{ marginRight: 2 }}
          >
            <p>Term Of Service</p>
          </div>
          <div
            onClick={() =>
              redirectTo("https://www.gamerarenaapp.com/privacy-policy")
            }
            style={{ paddingLeft: 5 }}
          >
            <p>Privacy Policy</p>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Homepage;

import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";

const TermOfService = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  return (
    <Grid
      container
      sx={{
        backgroundImage: "linear-gradient(45deg, #BA55D3 30%, #4B0082 90%)",
        p: 5,
      }}
      spacing={2}
    >
      <Grid item xs={12}>
        <Typography
          variant="h2"
          sx={{
            backgroundImage: "linear-gradient(45deg, #eedc82 30%, #e0ffff 90%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontWeight: "bold", // Kalın yazı
            letterSpacing: "-1px", // Harfleri birbirine yakınlaştırma
          }}
        >
          Term Of Service
        </Typography>
      </Grid>
      <Grid spacing={5}>
        <Typography variant="h4" sx={{ color: "white" }}>
          TERMS OF USE
        </Typography>
        <Typography variant="h5" sx={{ color: "white" }}>
          1. General Information
        </Typography>
        <Typography variant="body2" sx={{ color: "white" }}>
          1. Below are the Terms and Conditions to be accepted by the player
          during the registration process.
          <br />
          2. Gamerarena.com is operated by Gamer Arena Teknoloji Hizmetleri A.Ş,
          a company incorporated under Turkish Laws. Gamer Arena Teknoloji
          Hizmetleri A.Ş was registered on 07.03.2022 with registration number
          4661488051 and has its registered office at Abbasağa Mah. Yıldız Cad.
          Sungurlar Iş Merkezi No:45 İç kapı No:7 Beşiktaş/İstanbul
          <br />
          3. Gamer Arena Teknoloji Hizmetleri A.Ş makes no representations as to
          the legality of its service in your country of residence. This
          agreement is governed by and shall be construed in accordance with the
          laws of Turkey. The place of jurisdiction is Turkey.
          <br />
          4. Gamer Arena Teknoloji Hizmetleri A.Ş is referred to as "GA", "us",
          or "our", and the player as "you" or "player".
          <br />
          5. "Games" and "Game" are referred to as any of the game products
          offered by this application(s).
          <br />
          6. The term “Application” shall be referred to any/all the platforms
          where GA arranges games, duels or competitions.
          <br />
          7. There is only one version of these Terms and Conditions. These
          Terms and Conditions may be published in several languages to reflect
          the same principles, for informational purposes, and to assist
          players. However, the legal basis for the relationship between you and
          GA is only in the English version. In the event of any inconsistency
          between the English version and the non-English version of these Terms
          and Conditions, the English version shall prevail.
          <br />
          8. These Terms and Conditions constitute a legally binding agreement
          between the player and GA, GA App has the right to govern your use of
          the application. Please read these terms and rules carefully. GA
          reserves the right to make changes to these Terms and Conditions
          without notice. GA shall require the player to accept the revised
          Terms and Conditions, ensuring that the player is notified of such
          changes the next time they log into the application. If the Player
          refuses the changes, the Player shall be blocked from using the GA app
          . These Terms and Conditions shall be kept available and accessible to
          players at all times.
          <br />
          9. Players are responsible for regularly reviewing the terms and
          conditions to ensure they accept the terms to which the services are
          subject. It is also recommended that you review these Terms and
          Conditions each time you use the application.
          <br />
          10. These Terms and Conditions apply once you complete the
          registration process on the application. By creating an account on
          Gamer Arena, you declare that you have read and accepted these Terms
          and Conditions.
          <br />
          11. All necessary information regarding the game rules, any
          commissions or other fees charged by us shall be kept accessible at
          all times. This information is available in this agreement.
          <br />
        </Typography>
        <Typography variant="h5" sx={{ color: "white" }}>
          2. Your Obligations
        </Typography>
        <Typography variant="body2" sx={{ color: "white" }}>
          1. A user can only open one account on the application. GA has the
          right to close the account in cases of multiple account detection.
          <br />
          2. By accepting this agreement, you acknowledge that you are over the
          age of 13 and are subject to the laws applicable in your country of
          residence.
          <br />
          3. You are solely responsible for specific account details such as
          your username and password and any other linked email addresses used
          on the application as a means to access your account. In addition, you
          are responsible for the security of any personal computer or device on
          which you have registered your account and where your account can be
          accessed. If you suspect that such information has been compromised,
          you should notify us and take appropriate measures to prevent
          unauthorized access to any part of your account. GA cannot be held
          responsible for any unauthorized access to your account while GA is
          not at fault.
          <br />
          4. You can only play and take part in the games for yourself. You may
          not operate on behalf of another person or company.
          <br />
          5. You may participate in the Games for entertainment purposes only,
          in a non-professional capacity.
          <br />
          6. By accepting this agreement, you agree that you are not located in
          any country that prohibits competitive gaming.
          <br />
          7. You are not allowed to transfer funds from your account to other
          players, or transfer funds from other players to your account, or
          transfer, sell, and/or own user accounts.
          <br />
          8. You may participate in any game only if you have sufficient funds
          in your account for such participation.
          <br />
          9. You may not use any prohibited or illegally associated balance from
          any illegal activity or source.
          <br />
          10. You may only use credit cards and other payment methods that are
          valid and legally owned by you for depositing funds to your account.
          <br />
          11. In connection with your participation in any of the Games, you
          undertake that you will not be involved in any fraudulent, cheating,
          fixing, or other illegal activity and that you will not use any
          third-party software-supported methods or technical, exploitative
          hardware devices. In case of such behaviour, we reserve the right to
          void your participation in duels/games, reclaim any wages/progress
          earned, cancel your games.
          <br />
          12. You may use the Game that we designed for solely entertainment
          purposes, only if it complies with the rules, terms & conditions we
          have created as well as all applicable laws, rules, and regulations.
          <br />
          13. You must be supportive of other players using the application, as
          well as staff employed by GA, and refrain from making rude or obscene
          comments.
          <br />
          14. If you breach any of the obligations specified below, the Company
          may recourse to you for any damages, including those to third parties,
          or may take legal actions and remedies to compensate for the damages,
          and may suspend or close your account, provided that you are notified
          when necessary. The Company evaluates the legality of the information
          provided and its compliance with obligations, taking into account
          fundamental rights and freedoms such as freedom of expression and
          legality reasons such as public interest.
          <br />
          15. All personal data entered by you on the platform must be accurate
          and up-to-date.
          <br />
          16. If requested by the Company, your personal data must be verified
          and approved.
          <br />
          17. If changes or updates are required in personal data, the relevant
          sections must be updated. Otherwise, your current information shall be
          deemed to be correct.
          <br />
          18. The account belongs only to you. Therefore; you agree
          <br /> ● To choose a strong and secure password;
          <br /> ● To keep the password safe and confidential;
          <br /> ● Not to give any part of the account to anyone else and
          <br /> ● To comply with the laws and Terms of Use in this context.
          <br />
          19. Even if all reasonable precautions are taken, you are responsible
          for any actions taken regarding your account, other than the hijacking
          of your account by unrelated persons, or for systemic reasons beyond
          your control.
          <br />
          20. It is expressly prohibited to open an
          imitation/parody/fake/misleading account on the platform by using any
          person's information.
          <br />
          21. You may not include personal data, especially sensitive personal
          data, of unrelated third parties on the platform unless it is
          necessary and suitable for its purpose, and you cannot violate
          confidentiality agreements with any legal or natural person.
          <br />
          22. You cannot include content such as insults, slander, threats,
          etc., which can be considered crimes under the Turkish Penal Code No.
          5237, on the platform.
          <br />
          23. You may not include content that promotes or supports illegal or
          criminal activities or organizations on the platform.
          <br />
          24. You cannot open more than one account, put your account on sale,
          and have others use it for your own benefit or the benefit of a third
          party, for deception, spoofing, unfair gain, or other reasons.
          <br />
          25. You cannot include content that violates the intellectual property
          rights of third parties, such as trademarks and copyrights.
          <br />
        </Typography>
        <Typography variant="h5" sx={{ color: "white" }}>
          3. Your Account
        </Typography>
        <Typography variant="body2" sx={{ color: "white" }}>
          1. Before you can duel or make an iap, you must first personally
          register with Gamer Arena and open an account ("Account").
          <br />
          2. Within the scope of the membership and for creating an account, you
          give your consent in advance for the Company to create an account and
          profile for you from all the information you have provided and shared
          on the Platform, including KYC processes. In this context, any legal,
          criminal, and/or administrative responsibility in disputes that may
          arise regarding the accuracy of the information shared belongs to you.
          <br />
          3. You are only allowed to have one account and register it
          personally. If you try to open more than one account, any account you
          try to open may be blocked or closed, and any deposited/earned
          balances may be voided.
          <br />
          4. You agree that all the information you provide to us, including but
          not limited to valid ID, and email, is complete for the duration of
          this agreement. Likewise, you agree that you will promptly notify GA
          of any changes to such information or that you will change such
          details yourself on the application.
          <br />
          5. In the event that such information changes, you must update the
          mandatory information provided in the registration form and keep it up
          to date.
          <br />
          6. If you notice that you have more than one registered account, you
          must notify us immediately. Failure to do so may result in your
          account being blocked.
          <br />
          7. As part of the registration process, you will need to set your
          username and password to login to the application. It is your sole and
          exclusive responsibility to ensure that your login information is kept
          secure. You should not share your login information with anyone. We
          are not responsible for any third-party abuse or misuse of your
          account if you disclose your login information to any third party,
          whether your account is active or inactive, and whether the disclosure
          is intentional or accidental.
          <br />
          8. GA reserves the right, in its sole discretion, to refuse to open or
          close an account. However, any contractual obligations already made by
          GA shall be fulfilled accordingly, without prejudice to GA's statutory
          rights.
          <br />
          9. If you want to close your account, you can contact the live support
          section of our application. You can do this whenever you want. The
          effective closure of the account will correspond to the termination of
          the Terms and Conditions.
          <br />
          10. In the event a user account is closed, account-related information
          is retained for up to five (5) years from the moment the account is
          closed. Relevant information includes player name, date of birth,
          country, language, email, and account transaction details
          <br />
        </Typography>
        <Typography variant="h5" sx={{ color: "white" }}>
          4. Game Rules
        </Typography>
        <Typography variant="body2" sx={{ color: "white" }}>
          1. As part of the Services, the player may enter certain competitive
          multiplayer skill-based paid games ("Games"). The winner of each game
          shall be determined based on their skills in relation to other users
          competing in the game.
          <br />
          2. The Player explicitly accepts and declares that Apple (“Apple
          Inc.”) is not a sponsor of any contest or sweepstake rules that shall
          partake in GA’s application(s). To avoid any doubt Apple does not
          undertake any of the games/duels/sweepstakes that shall occur in GA’s
          application(s).
          <br />
          3. The entry fee and value of the amount offered in each game will be
          announced before the start of each game. The player can refund the
          entry fee for any game. However, the player accepts and declares that
          any entrance fee paid after the game has started will not be refunded
          and the player will not be able to win the game. At the start of the
          game, all entrance fees paid will be charged to the player's balance
          account. The player will be solely responsible for the payment of all
          entry fees.
          <br />
          4. As for the games hosted on the GA's application(s), the
          application’s internal software will determine the game results. GA
          shall have sole and absolute discretion to determine the results and
          winners of the Duel, and by participating in any duel, the player
          agrees to abide by these determinations. After the duel winner is
          determined by GA, GA shall receive ten percent (10%) of all prize
          amounts won by the duel winner. The remaining prize amount will then
          be credited to or transferred to the duel winner's account.
          <br />
          5. The player is solely responsible for complying with the rules we
          set for the games within the application. In case of non-compliance,
          GA has the right to disqualify the user from the game, and also GA has
          the right to fine and/or suspend the Player's account in GA.
          <br />
        </Typography>
        <Typography variant="h5" sx={{ color: "white" }}>
          5. Exceptional Circumstances
        </Typography>
        <Typography variant="body2" sx={{ color: "white" }}>
          1. Circumstances beyond GA's control, such as unexpected technical
          issues or technical issues involving third-party providers, allow GA
          to cancel the game and refund players.
          <br />
          2. GA reserves the right to limit balances, cancel balances if the
          player's actions have taken place in such a way that the system has
          been abused.
          <br />
          3. If a refund is decided, the refund amount shall be immediately
          credited to the player's account.
          <br />
          4. In the event that access to a game is interrupted in a way that
          cannot be rectified, for example, in the event of a loss of connection
          while playing, GA has the right to regularly "clear" such entry fees
          and return the entry fees to the player's account. If the game has
          been stopped or crashed on the server, the player shall be refunded.
          <br />
          5. If a game has a bug or misconfiguration that causes misbehaviour or
          payouts, GA has the right to remove the game from the system and
          change players' balances and account details to fix the bug.
          <br />
          6. GA is not responsible for any interruptions, server interruptions,
          delays, or any technical or system disturbances in the game. Refunds
          may only be granted at management's discretion.
          <br />
          7. GA accepts no liability for any damage or loss assumed or alleged
          to arise out of or in connection with the application or its content,
          including, without limitation, delays or interruptions in operation or
          transmission, loss or contamination of data, communication or line
          failures, misuse of the application or its content by any person, or
          any errors or omissions in the content.
          <br />
          8. GA may remove any game from its application(s) at any time it deems
          appropriate.
          <br />
        </Typography>
        <Typography variant="h5" sx={{ color: "white" }}>
          6. Inactive Accounts
        </Typography>
        <Typography variant="body2" sx={{ color: "white" }}>
          1. An inactive account is an account that has not been accessed for
          twelve (12) months and has a balance.
          <br />
          2. Up to thirty (30) days prior to the deactivation of your Account,
          GA shall contact you via e-mail, notifying you that your account will
          be deactivated and that all fees that may be charged in connection
          therewith are included in the account.
          <br />
          3. GA shall reimburse these fees if you access your account within
          three (3) months from the date the inactive account fee was incurred.
          <br />
          4. GA may charge an inactive account as long as it does not create a
          negative balance.
          <br />
          5. If you have not logged into the account for a long time and are not
          in a position to accept any material changes to the Terms and
          Conditions, all fees charged to the account shall be in accordance
          with the Terms and Conditions you most recently accepted.
          <br />
          6. The procedure for removing balances from inactive accounts is done
          automatically and reported in the software system of the application
          management interface.
          <br />
        </Typography>
        <Typography variant="h5" sx={{ color: "white" }}>
          7. Privacy Policy
        </Typography>
        <Typography variant="body2" sx={{ color: "white" }}>
          1. You acknowledge and agree that we collect and use your personal
          data to allow you to access and use the application and to participate
          in games.
          <br />
          2. We agree to be bound by the General Data Protection Regulation (EU)
          2016/679 when collecting your personal information, as stated in the
          previous clause. We will protect your personal information and respect
          your privacy in accordance with best business practices and applicable
          laws.
          <br />
          3. Identity and contact information:
          <br />
          4. Data Controller: Abdullah Özşahin
          <br />
          5. Data Protection Officer: Abdullah Özşahin
          <br />
          6. Your personal data will be used by us to allow you to participate
          in the games and to take actions related to your participation in the
          games.
          <br />
          7. We may also use your personal data to inform you about promotions,
          changes, and new services that we think you may find interesting. To
          receive such direct marketing data, you must register for such service
          by managing your settings or contacting support.
          <br />
          8. Your personal data will not be given to third parties unless
          necessary to process your requests regarding your participation in the
          games unless specifically permitted by you or unless required by law.
          As GA's business partners, suppliers, or service providers may be
          responsible for the overall or certain parts of the operation of the
          application, personal data may be transmitted to them. Relevant
          authorities and GA employees, especially the customer support line and
          other employees, may access your personal data to perform their duties
          and provide you with assistance and services. You are deemed to have
          accepted such disclosures.
          <br />
          9. In order to provide you with an efficient service, we and our
          service providers may need to transfer your personal data from one
          country to another in the EU and EFTA regions.
          <br />
          10. Our retention period is reserved for data that does not exceed 1
          year.
          <br />
          11. While you have the right to access, correct, delete, and restrict
          your data, you also have the right to make data portability requests.
          <br />
          12. GA does not knowingly collect personal data from children. Since
          the players below age of 13 are not permitted in the system, in cases
          of violation of this GA shall not be responsible for the consequences.
          <br />
          13. You can object to the processing of your data and withdraw your
          consent if the processing is based on your consent.
          <br />
          14. You have the opportunity to lodge a complaint with the relevant
          supervisory authority for all actions.
          <br />
          15. The Company has the right to contact you, to notify you about new
          products and system improvements, and to reach and send e-mails to you
          for other purposes via the contact addresses specified by you during
          registration, membership, and account creation on the Platform, or
          updated by you later. By accepting these Terms of Use, you accept and
          declare that the Company may engage in the above-mentioned
          communication activities unless otherwise stated. You can unsubscribe
          from the e-mail list with one click through the account settings or by
          using the "I want to unsubscribe" link in the e-mail sent to you.
          <br />
          16. Playtime (adjoe) is a third feature that is integrated with our
          app. Playtime allows users to earn in-app currency by playing
          third-party games and collects devices’ application usage from users.
          The processing of the data is necessary to recommend, via system
          messages, the installation of the app available in Playtime that
          matches users’ interests and calculates the bonuses acquired as a
          result of users’ use of the corresponding apps. For more information,
          please refer to Playtime (adjoe) Data Privacy.”
          https://adjoe.zone/legal/datenschutz.html.
          <br />
          17. GA shall not be responsible for any of the rewards that the
          players should receive for participating in the advertisements. These
          rewards shall be granted by the advertiser. To avoid any doubt GA does
          not undertake to grant rewards related to the advertisements within
          the application(s). Additionally, GA shall not be liable for any
          damages that may incur due to advertisements placed within the
          application(s).
          <br />
        </Typography>
        <Typography variant="h5" sx={{ color: "white" }}>
          8. Player Complaints
        </Typography>
        <Typography variant="body2" sx={{ color: "white" }}>
          1. GA strives to make a player's experience with us enjoyable, but
          there may be occasions when a player may not be satisfied with the
          quality of our products or customer service. The player can make a
          complaint by sending an email to Customer Service at
          [info@gamerarena.com]. Your complaint is forwarded to management,
          which tries to resolve the complaint within 48 hours of receiving it.
          <br />
          2. If your complaint is not resolved by the above procedures, you may
          file a claim with the Turkey Arbitration Center as a registered
          Alternative Dispute Resolution.
          <br />
        </Typography>
        <Typography variant="h5" sx={{ color: "white" }}>
          9. Limitation of Liability
        </Typography>
        <Typography variant="body2" sx={{ color: "white" }}>
          1. You enter the application(s) and participate in the games at your
          own risk. Application(s) and games are provided without warranty of
          any kind, either expressed or implied.
          <br />
          2. Without prejudice to the generality of the previous paragraph, we,
          our directors, employees, partners, and service providers;
          <br />
          3. Do not guarantee that the software, games, and application(s) are
          suitable for your purposes;
          <br />
          4. Do not guarantee that the software, games, and application(s) are
          error-free;
          <br />
          5. Do not guarantee that the software, games, and application(s) will
          be available uninterruptedly;
          <br />
          6. GA shall not be liable for any direct, indirect, special,
          consequential, or any other loss, cost, expense, or damage arising out
          of your use of the application(s) or participation in the games.
          <br />
          7. You understand and agree that in the event of a failure in a game
          or its co-accessibility, duels made during such failure will be void.
          Any subsequent game rounds entered with such balances will be voided,
          regardless of the proceeds from a faulty game and which games were
          played with those proceeds.
          <br />
          8. You hereby agree to fully indemnify us, our managers, employees,
          partners, and service providers for any and all costs, expenses,
          losses, damages, claims, and liabilities that may arise in connection
          with your use of or participation in the application(s) and the games.
          <br />
          9. During the account creation of the member, if the platform
          redirects you to another pop-up page or tab, the information you fill
          in on this page and the files you upload are processed and saved in
          the systems of the said company or third party, not in the Company's
          systems. The Company has no right to intervene in such and similar
          cases. When any information is entered by you by following the links
          given on the Platform, the Company has no responsibility for the
          content and accuracy of this information.
          <br />
          10. The Company has no obligation to investigate the accuracy of the
          information you provide and share on the Platform, including the
          information you provide in KYC processes, within the scope of
          Membership and to create an account. Any legal, criminal and/or
          administrative responsibility for the disputes that may arise
          regarding the accuracy of the information provided shall be yours.
          <br />
        </Typography>
        <Typography variant="h5" sx={{ color: "white" }}>
          10. Violations, Penalties and Termination
        </Typography>
        <Typography variant="body2" sx={{ color: "white" }}>
          1. If you violate any provision of these Terms and Conditions, or if
          we have reasonable grounds to suspect that you have violated them, we
          reserve the right to withhold, suspend, close your account and apply
          any damage to your balance.
          <br />
          2. If GA suspects that you have engaged in illegal and/or fraudulent
          activities while using the application, or that you are in breach of
          this agreement, have problems with creditors, or otherwise harm our
          business, we may, in our sole discretion, freeze or terminate your
          account or cancel any rewards.
          <br />
          3. You agree that GA shall be the final decision maker as to whether
          you violate our rules, Terms and Conditions, resulting in a suspension
          or permanent ban from your participation in our application(s).
          <br />
        </Typography>
        <Typography variant="h5" sx={{ color: "white" }}>
          11. Entire Agreement
        </Typography>
        <Typography variant="body2" sx={{ color: "white" }}>
          1. If any provision of these Terms and Conditions is found to be
          illegal or unenforceable, such provision(s) shall be removed from
          these Terms and Conditions and all other provisions shall remain in
          effect unaffected by such interruption.
          <br />
          2. We reserve the right to assign or otherwise legally transfer this
          agreement. You may not transfer or otherwise lend this contract.
          <br />
          3. These Terms and Conditions constitute the entire agreement between
          you and us regarding the application and supersede all prior or
          simultaneous communications and offers between you and us, whether
          electronic, verbal or written.
        </Typography>
        <Typography variant="h5" sx={{ color: "white" }}>
          12. Applicable Law and Jurisdiction
        </Typography>
        <Typography variant="body2" sx={{ color: "white" }}>
          1. These Terms and Conditions shall be governed by the Laws of
          Turkey.
          <br />
          2. The parties agree that any dispute or claim arising out of or in
          connection with these Terms and Conditions, or any breach, termination
          or invalidity thereof, shall be submitted to the exclusive
          jurisdiction of the Courts of Turkey.
          <br />
        </Typography>
        <Typography variant="h5" sx={{ color: "white" }}>
          13. Purposes of Processing Your Personal Data
        </Typography>
        <Typography variant="body2" sx={{ color: "white" }}>
          1. In order to complete your membership procedures and participation
          in the platform, as well as to complete the KYC processes, and to take
          precautions regarding privacy and security, the information you share
          with us through the platform during and after account creation and
          membership processes is shared with third parties.
          <br />
          2. When you open an account on the GA application(s), your information
          may be shared
          <br />
          3. In order to evaluate whether the account and account conditions are
          met,
          <br />
          4. To examine the accounts and to identify new users who create/will
          create an account,
          <br />
          5. To verify your identity data and record your information for future
          confirmation,
          <br />
          6. With our lawyers, to exercise our right of defence when necessary.
          <br />
          7. In order to provide services, we share the data with third parties
          based abroad and with our service providers who are based abroad and
          with whom we have a data controller/data processor relationship.
          <br />
          8. Your personal data shall be retained for the maximum period
          specified in the relevant legislation or required for the purpose for
          which they are processed, and in any case, for the statutory
          limitation periods.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TermOfService;
